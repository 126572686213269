<template>
  <ul class="menu-nav">
    <router-link
      to="/companies/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> All Companies </span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/companies/new"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Add New </span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/discounts-bundles/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">Discount Bundles</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'CompanyMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    }
  }
}
</script>
