<template>
  <!-- begin:: Footer -->
  <div class="footer py-4 d-flex flex-lg-column " id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <!--begin::Copyright-->
      <div class="text-white order-2 order-md-1">
        <span class="font-weight-bold mr-2">2021©</span>
        <a
          href="https://mastercentar.hr"
          target="_blank"
          class="text-white text-hover-primary"
          >MasterCentar</a
        >
      </div>
      <!--end::Copyright-->
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KTFooter',
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid'
    }
  }
}
</script>
