<template>
  <div
    class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between w-100"
  >
    <div
      class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
    >
      <router-link
        to="/orders/list"
        class="btn btn-light-success font-weight-bold mr-3 my-2 my-lg-0"
      >
        Latest Orders
      </router-link>
    </div>
    <div class="d-flex align-items-center">
      <a href="#" class="btn btn-mc font-weight-bold my-2 my-lg-0"
        >Generate New Order</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    }
  }
}
</script>
