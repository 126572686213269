<template>
  <div
    class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-between w-100"
  >
    <div
      class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
    >
      <ul class="menu-nav">
        <router-link
          to="/products/list"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text"> All Products </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/products/new"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text"> Add New </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/products-categories/list"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text"> Categories </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/products-attributes/list"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text"> Attributes </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/brands/list"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text"> Brands </span>
            </a>
          </li>
        </router-link>
        <router-link
          to="/tags/list"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text"> Tags </span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
    <div class="d-flex align-items-center"></div>
  </div>
</template>

<script>
export default {
  name: 'ProductMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1
    }
  }
}
</script>
